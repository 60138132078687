define("admin/routes/clients/client/missions/index", ["exports", "ember-infinity/mixins/route", "admin/mixins/mission-share", "admin/helpers/format-price", "admin/config/environment"], function (_exports, _route, _missionShare, _formatPrice, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_route.default, _missionShare.default, {
    model(params) {
      var client;
      client = this.modelFor('clients.client').client;
      params['perPage'] = 10;
      params['startingPage'] = 1;
      params['client_id'] = client.id;
      params['modelPath'] = 'controller.missions';
      params['q'] = params['missions_query'];
      let missionSort = params['missionsSortProps'][0].split(':');
      params['sort_attribute'] = missionSort[0];
      params['sort_order'] = missionSort[1];
      return Ember.RSVP.hash({
        client,
        flightApps: this.store.findAll('flight_app'),
        missions: this.infinityModel('mission', params),
        verticals: this.store.findAll('vertical'),
        shot_lists: this.store.findAll('shot_list'),
        accountReps: this.store.query('admin', {
          role: 'account_rep'
        }),
        badges: this.store.query('badge', {}),
        public_packages: this.store.query('package', {}).then(function (response) {
          return response.sortBy('organization.name', 'vertical.short_name', 'position', 'price', 'name');
        }),
        client_packages: this.store.query('package', {
          client_id: client.id
        }).then(function (response) {
          return response.sortBy('vertical.short_name', 'position', 'price', 'name');
        }),
        drones: this.store.findAll('drone'),
        devices: this.store.findAll('device'),
        equipment: this.store.findAll('pilot_equipment'),
        work_types: this.store.findAll('deliverable_work_type'),
        package_addons: this.store.findAll('package_addon'),
        actions: this.actions,
        sites: this.infinityModel('site', {
          client_id: client.id,
          starting_page: 1,
          per_page: 100,
          q: params['sites_query'],
          sort: params['sitesSortProps'][0]
        }),
        currencies: this.store.query('currency', {
          all: true
        })
      });
    },
    queryParams: {
      missions_query: {
        refreshModel: true
      },
      missionsSortProps: {
        refreshModel: true
      },
      sites_query: {
        refreshModel: true
      },
      sitesSortProps: {
        refreshModel: true
      },
      edit_package: {
        refreshModel: true
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set('client', model.client);
      controller.set('missions', model.missions);
      controller.set('sites', model.sites.map(site => {
        const currency = model.currencies.findBy('id', site.get('last_mission_currency_id'));
        site.set('last_mission_price_formatted', (0, _formatPrice.formatPrice)([site.get('last_mission_price'), currency]));
        return site;
      }));
      model.shotListsForSelect = model.shot_lists.toArray().sortBy('name');
      model.verticalsForSelect = model.verticals.toArray().sortBy('name');
      return model.client_packages_array = model.client_packages.toArray();
    },
    renderTemplate(controller, model) {
      this._super(controller, model);
      if (controller.get('edit_package')) {
        const editPackage = model.client_packages.findBy('number', controller.get('edit_package'));
        if (editPackage) this.editPackage(model, editPackage);
      }
    },
    editPackage(model, edit_package) {
      delete model["package"];
      model["package"] = edit_package;
      return this.send('openModal', 'clients.client.missions.modal-package', model);
    },
    actions: {
      newPackage(model) {
        delete model["package"];
        model["package"] = model.client.get('packages').createRecord({
          currency: model.currencies.findBy('code', 'USD')
        });
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      editPackage(model, edit_package) {
        return this.editPackage(model, edit_package);
      },
      clonePackage(model, clone_package) {
        delete model["package"];
        model["package"] = model.client.get('packages').createRecord({
          name: clone_package.get('name'),
          vertical: clone_package.get('vertical'),
          shot_list: clone_package.get('shot_list'),
          price: clone_package.get('price'),
          accept_restricted_airspace: clone_package.get('accept_restricted_airspace'),
          skip_asset_verification: clone_package.get('skip_asset_verification'),
          skip_instructions_confirmation: clone_package.get('skip_instructions_confirmation'),
          mission_instructions: clone_package.get('mission_instructions'),
          mission_internal_notes: clone_package.get('mission_internal_notes'),
          mission_production_notes: clone_package.get('mission_production_notes'),
          default_pilot_script: clone_package.get('default_pilot_script'),
          estimated_pilot_payout: clone_package.get('estimated_pilot_payout'),
          estimated_analyst_payout: clone_package.get('estimated_analyst_payout'),
          pilot_instructions: clone_package.get('pilot_instructions'),
          badge: clone_package.get('badge'),
          badge_required: clone_package.get('badge_required'),
          auto_ortho_stitching: clone_package.get('auto_ortho_stitching'),
          auto_dispatch_enabled: clone_package.get('auto_dispatch_enabled'),
          deliverableWorkTypes: clone_package.get('deliverableWorkTypes'),
          require_raw_images: clone_package.get('require_raw_images'),
          description: clone_package.get('description'),
          description_bullet_points: clone_package.get('description_bullet_points'),
          pointivo_package_id: clone_package.get('pointivo_package_id'),
          estimated_roof_measurement_payout: clone_package.get('estimated_roof_measurement_payout'),
          order_flow: clone_package.get('order_flow'),
          catalog_items: clone_package.get('catalog_items'),
          currency: clone_package.get('currency')
        });
        return this.send('openModal', 'clients.client.missions.modal-package', model);
      },
      savePackage(model) {
        return model["package"].save().then(function (_this) {
          return function () {
            if (!model.client_packages_array.includes(model["package"])) {
              model.client_packages_array.pushObject(model["package"]);
              model.client.reload();
            }
            return _this.send('closeModal');
          };
        }(this), function () {
          return function () {};
        }(this));
      },
      selectPackageToggle(model, client) {
        var requestType;
        requestType = 'POST';
        return Ember.$.ajax({
          url: _environment.default.api.host + "/v1/admin/clients/" + client.id + "/notifications",
          headers: this.get('headers'),
          type: requestType,
          dataType: 'json',
          data: {
            package_id: model.id
          }
        }).then(function (_this) {
          return function () {
            _this.get('mission').reload();
            _this.set('message', 'Sent!');
            return _this.set('state', 'confirmed');
          };
        }(this), function (_this) {
          return function (response) {
            _this.set('state', 'error');
            return _this.set('message', response.responseJSON.errors[0].detail);
          };
        }(this));
      },
      close() {
        return this.send('closeModal');
      }
    }
  });
});